<template>
  <Layout>
    <div class="container-fluid">
      <div class="row mt-5">
        <div class="offset-lg-1 col-lg-10">
          <div class="title">Profile</div>

          <div class="back-link mt-3 mb-4" @click="OnClickedBackBtn">
            <i class="las la-arrow-alt-circle-left"></i>
            <span> Back</span>
          </div>

          <BootstrapAlert/>

          <div class="profile-row">
            <div class="row" v-show="EditTypeEnum.NAME !== this.edit_enabled">
              <label>{{$t("username")}}</label>
              <div class="col-10">
                <span class="text">{{this.currentUser.name}}</span>
              </div>
              <div class="col-2 text-end">
                <i class="las la-edit" @click="this.OnClickedEditBtn(EditTypeEnum.NAME)"></i>
              </div>
            </div>

            <div class="row" v-show="EditTypeEnum.NAME === this.edit_enabled">
              <label>{{$t("change_username")}}</label>
              <div class="col-10 col-md-4 col-lg-4">
                <input @input="OnUpdateNameText"
                       class="form-control input"
                       type="text"
                       :placeholder="[[$t('type_in')]]"
                       :value="this.user.name"
                >
                <div v-for="(item, index) in v$.user.name.$errors" :key="index" class="red-color">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <div class="col-2  col-md-8 col-lg-8 text-end">
                <i class="las la-edit text-gray" @click="this.OnClickedEditBtn(EditTypeEnum.DEFAULT)"></i>
              </div>
              <div class="col-md-12">
                <button class="submit mt-3" @click="OnClickedChangeBtn(EditTypeEnum.NAME)">{{$t("change")}}</button>
              </div>
            </div>
            <hr>
          </div>


          <div class="profile-row">
            <div class="row">
              <label>{{$t("email")}}</label>
              <div class="col-10">
                <span class="text">{{this.currentUser.email}}</span>
              </div>
            </div>
            <hr>
          </div>

          <div class="profile-row">
            <div class="row" v-show="EditTypeEnum.PASSWORD !== this.edit_enabled">
              <label>{{$t("password")}}</label>
              <div class="col-10">
                <span class="text">••••••••••</span>
              </div>
              <div class="col-2 text-end">
                <i class="las la-edit" @click="this.OnClickedEditBtn(EditTypeEnum.PASSWORD)"></i>
              </div>
            </div>

            <div class="row" v-show="EditTypeEnum.PASSWORD === this.edit_enabled">

              <div class="col-10 col-md-3 col-lg-3">
                <label>{{$t("current_password")}}</label>
                <input @input="OnUpdateCurrentPasswordText" class="form-control input" type="password" :placeholder="[[$t('type_in')]]">
                <div v-for="(item, index) in v$.user.current_password.$errors" :key="index" class="red-color">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>

              <div class="col-10 col-md-3 col-lg-3">
                <label>{{$t("new_password")}}</label>
                <input @input="OnUpdatePasswordText" class="form-control input" type="password" :placeholder="[[$t('type_in')]]">
                <div v-for="(item, index) in v$.user.password.$errors" :key="index" class="red-color">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <div class="col-10 col-md-3 col-lg-3">
                <label>{{$t("confirm_password")}}</label>
                <input @input="OnUpdateConfirmPasswordText" class="form-control input" type="password" :placeholder="[[$t('type_in')]]">
                <div v-for="(item, index) in v$.user.confirm_password.$errors" :key="index" class="red-color">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>

              <div class="col-2  col-md-3 col-lg-3 text-end">
                <i class="las la-edit text-gray" @click="this.OnClickedEditBtn(EditTypeEnum.DEFAULT)"></i>
              </div>

              <div class="col-md-12">
                <button class="submit mt-3" @click="OnClickedChangeBtn(EditTypeEnum.PASSWORD)">{{$t("change")}}</button>
              </div>
            </div>
            <hr>
          </div>

          <div class="profile-row">
            <div class="row">
              <div class="col-10 col-md-4 col-lg-4">
                <label>{{$t("card_brand")}}</label>
                <div class="text">{{this.currentUser.card_brand}}</div>
              </div>

              <div class="col-10 col-md-4 col-lg-4">
                <label>{{$t("card_last4")}}</label>
                <div class="text" v-show="this.currentUser.card_last4">●●●● ●●●● ●●●● {{this.currentUser.card_last4}}</div>
              </div>

              <div class="col-10 col-md-4 col-lg-4">
                <label>{{$t("card_exp_date")}}</label>
                <div class="text">{{this.currentUser.card_exp_date}}</div>
              </div>
            </div>
            <hr>
          </div>

        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import appConfig from "../../../../app.config.json";
import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import EditTypeEnum from "@/enums/EditTypeEnum";
import BootstrapAlert from '@/components/utils/Alert';

import {
  required,
  helpers,
  sameAs
} from "@vuelidate/validators";

import useVuelidate from "@vuelidate/core";
export default {
  page: {
    title: "Profile",
    meta: [{
      name: "description",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    }],
  },
  name: "profile",
  setup(){
    return {
      v$: useVuelidate()
    }
  },
  data(){
    return {
      EditTypeEnum: EditTypeEnum
    }
  },
  validations(){
    return{
      user: {
        name: {
          required: helpers.withMessage("Name is required", required),
        },
        password: {
          required: helpers.withMessage("New Password is required", required),
        },
        confirm_password: {
          sameAsPassword: sameAs(this.user.password)
        },
        current_password: {
          required: helpers.withMessage("Current Password is required", required),
        }
      },
    }
  },
  components: {
    Layout,
    BootstrapAlert
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("profile", ["edit_enabled", "user"])
  },
  beforeMount() {
    this.setCurrentUser();
    //this.getUserInfo(this.currentUser.id);
  },
  methods: {
    ...mapActions("profile", ["getUserInfo", "setEditEnabled", "setNameText", "setPasswordText",
      "setConfirmPasswordText", "setCurrentPasswordText", "changeName", "changePassword", "setCurrentUser"]),

    OnClickedBackBtn(){
      router.push({ name: "home" });
    },

    OnClickedEditBtn(index){
      this.setEditEnabled(index);
    },

    OnUpdateNameText(e){
      this.setNameText(e.target.value);
    },

    OnUpdatePasswordText(e){
      this.setPasswordText(e.target.value);
    },

    OnUpdateConfirmPasswordText(e){
      this.setConfirmPasswordText(e.target.value);
    },

    OnUpdateCurrentPasswordText(e){
      this.setCurrentPasswordText(e.target.value);
    },

    OnClickedChangeBtn(index){
      switch (index) {
        case EditTypeEnum.NAME:
          this.v$.user.name.$touch();
          if (this.v$.user.name.$invalid) {
            return;
          }
          this.$isLoading(true);
          this.changeName()
              .finally(() => {
                this.$isLoading(false);
          });
          break;
        case EditTypeEnum.PASSWORD:
          this.v$.user.current_password.$touch();
          this.v$.user.password.$touch();
          this.v$.user.confirm_password.$touch();
          if (this.v$.user.current_password.$invalid || this.v$.user.password.$invalid || this.v$.user.confirm_password.$invalid) {
            return;
          }
          this.$isLoading(true);
          this.changePassword().finally(() => {
            this.$isLoading(false);
          });
          break;
      }
    },
  }
}
</script>

<style lang="scss" src="./profile.scss"></style>